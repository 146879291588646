import { useState, useEffect } from 'react';
import BurgerHeader from "./burger";
import './style.css';
import menuInformation from './data';
import Button from "./button/index";
import HeaderLogo from "../logo";

const Nav = (props) => {
  const [activeMenuItem, setActiveMenuItem] = useState('');

  const handleMenuItemClick = (item_menu) => {
    const element = document.getElementById(item_menu.href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setActiveMenuItem(item_menu.id);
      // Fecha o menu burger apenas se estiver ativo (modo mobile)
      if (props.isActiveBurger) {
        props.handleActiveBurger(false);
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      let currentSection = '';
      menuInformation.forEach(item => {
        const section = document.getElementById(item.href);
        if (section) {
          const rect = section.getBoundingClientRect();
          if (
            rect.top <= window.innerHeight / 2 &&
            rect.bottom >= window.innerHeight / 2
          ) {
            currentSection = item.id;
          }
        }
      });
      setActiveMenuItem(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Chamada inicial

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className="nav">
      <BurgerHeader handleActiveBurger={props.handleActiveBurger} />
      <div className={`navMenu ${props.isActiveBurger ? 'active' : ''}`}>
        <HeaderLogo /> {/* A logo permanece dentro do navMenu */}
        <ul>
          {menuInformation.map(item_menu => (
            <li
              key={item_menu.id}
              className={activeMenuItem === item_menu.id ? 'active' : ''}
              onClick={() => handleMenuItemClick(item_menu)}
            >
              {item_menu.text}
            </li>
          ))}
        </ul>
        <Button />
      </div>
    </nav>
  );
};

export default Nav;
