import './style.css';
import Title from './title';
import Infos from './infos';
import Mail from './mail';

const Contact = () => {
  return (
    <div id="myContact">
      <Title></Title>
      <div id='boxContact'>
        <Infos></Infos>
        <Mail></Mail>
      </div>
    </div>
  )
}

export default Contact;
