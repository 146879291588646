import { useState, useEffect } from 'react';
import './style.css';
import coverImg from '../../../../assets/cover.jpg';
import emailjs from '@emailjs/browser';

const NewsletterButton = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [shouldRenderModal, setShouldRenderModal] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [animateContent, setAnimateContent] = useState(false);

  const handleModalToggle = () => {
    if (isModalOpen) {
      setModalOpen(false);
      // Resetar a mensagem de sucesso e animações quando o modal for fechado
      setTimeout(() => {
        setShowSuccessMessage(false);
        setAnimateContent(false);
      }, 500); // Deve coincidir com a duração da animação de fechamento
    } else {
      setShouldRenderModal(true);
      setModalOpen(true);
    }
  };

  useEffect(() => {
    if (!isModalOpen) {
      const timer = setTimeout(() => {
        setShouldRenderModal(false);
      }, 500); // Duração da animação de fechamento
      return () => clearTimeout(timer);
    }
  }, [isModalOpen]);

  const handleSubscribe = (e) => {
    e.preventDefault();

    // Validação de e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim() || !emailRegex.test(email)) {
      // Animação de shake
      const emailInput = document.querySelector('.newsletter-modal-content input[type="text"]');
      if (emailInput) {
        emailInput.classList.add('shake-animation');
        emailInput.addEventListener(
          'animationend',
          () => {
            emailInput.classList.remove('shake-animation');
          },
          { once: true }
        );
      }
      return;
    }

    setLoading(true);

    // Certifique-se de que o nome da variável corresponde ao usado no template do EmailJS
    const templateParams = {
      from_name: email, // Substitua 'from_name' pelo nome da variável no seu template
    };

    emailjs
      .send('service_77eduq9', 'template_y6af1mg', templateParams, 'Bev4a1b4ZXVBgfqz7')
      .then(
        (response) => {
          console.log('Email enviado com sucesso', response.status, response.text);
          setEmail('');
          setLoading(false);

          // Iniciar a animação de transição do conteúdo
          setAnimateContent(true);

          // Após a animação, mostrar a mensagem de sucesso
          setTimeout(() => {
            setShowSuccessMessage(true);
            setAnimateContent(false);
          }, 500); // Deve coincidir com a duração da animação no CSS

          // Fechar o modal após alguns segundos
          setTimeout(() => {
            setModalOpen(false);
            // Resetar a mensagem de sucesso após o modal fechar
            setTimeout(() => {
              setShowSuccessMessage(false);
            }, 500); // Deve coincidir com a duração da animação de fechamento
          }, 1500); // O modal permanecerá aberto por 3 segundos exibindo a mensagem de sucesso
        },
        (err) => {
          console.error('Erro ao enviar e-mail', err);
          setLoading(false);
          // Se desejar, você pode adicionar uma notificação de erro aqui
        }
      );
  };

  return (
    <>
      <div className="newsletter-buttonBox">
        <button className="newsletter-ctaButton" onClick={handleModalToggle}>
          NewsLetter
        </button>
      </div>

      {shouldRenderModal && (
        <div
          className={`newsletter-modal-overlay ${
            isModalOpen ? 'newsletter-modal-open' : 'newsletter-modal-close'
          }`}
        >
          <div className="newsletter-modal-content">
            <button className="newsletter-close-button" onClick={handleModalToggle}>
              X
            </button>

            {/* Wrapper do conteúdo */}
            <div
              className={`newsletter-content-wrapper ${
                animateContent ? 'animate-out' : ''
              } ${showSuccessMessage ? 'hidden' : ''}`}
            >
              {/* Contêiner de imagem */}
              <div className="newsletter-image-container">
                <img src={coverImg} alt="Imagem do Modal" />
              </div>
              <h2>Assine nossa Newsletter</h2>
              <p>
                Fique por dentro das últimas tendências e inovações em telecomunicações e tecnologia.
                Não perca nenhuma novidade!
                <br />
                <br />
                Newsletter semanal
              </p>
              {/* Formulário */}
              <form onSubmit={handleSubscribe} noValidate>
                <input
                  type="text" // Alterado de 'email' para 'text'
                  placeholder="Digite seu e-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {/* Botão com estado de carregamento */}
                <button
                  type="submit"
                  className={`newsletter-submit-button ${loading ? 'loading' : ''}`}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="newsletter-button-spinner"></div>
                  ) : (
                    'Inscrever-se'
                  )}
                </button>
              </form>
            </div>

            {/* Mensagem de Sucesso */}
            {showSuccessMessage && (
              <div className="newsletter-success-message animate-in">
                <h2>Inscrição realizada com sucesso!</h2>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default NewsletterButton;
