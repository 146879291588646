import './style.css';

function Title() {
  return (
  <div>
    <div id="title">
      <span>Entre em Contato</span>
    </div>
    <div id="textTitle">
      <span>Alguma pergunta ou comentário? Basta nos escrever uma mensagem!</span>
    </div>
  </div>
  );
}

export default Title;
