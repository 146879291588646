import './style.css'
import telephone from './../../../assets/telephone.png'
import map from './../../../assets/map.png'
import mail from './../../../assets/mail.png'
import facebook from './../../../assets/facebook.png'
import instagram from './../../../assets/instagram.png'
import whats from './../../../assets/whats.png'

function Infos() {
  return(
    <div id='box'>
      <div id='boxBlue'>
        <div id='topIinfo'>
          <div id='titleInfo'>
            <span>Informações de Contato</span>
          </div>
          <div id='textInfo'>
            <span>Fale com a gente agora mesmo!</span>
          </div>
        </div>
        <div id='icons'>
          <div id='boxTelephone'>
            <img src={telephone} alt="Telephone" />
            <span>+55 48 99100-7780</span>
          </div>
          <div id='boxMail'>
            <img src={mail} alt="Mail" />
            <span>contato@kernellintelligence.com.br</span>
          </div>
          <div id='boxMap'>
            <img src={map} alt="Map" />
            <span>Florianopolis, SC, Brasil</span>
          </div>
        </div>
        <div id='socialMedia'>
          <div id='socialMediabutton'>
          <a href="https://www.facebook.com/kernellintelligence" target="_blank" rel="noopener noreferrer">
            <img src={facebook} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/kernellintelligence/" target="_blank" rel="noopener noreferrer">
            <img src={instagram} alt="Instagram" />
          </a>
          <a href="https://wa.me/5548991007780" target="_blank" rel="noopener noreferrer">
            <img src={whats} alt="WhatsApp" />
          </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Infos;