import './style.css'

function Title() {
  return(
    <div id='boxTitleAbout'>
      <span id='titleAbout'>Sobre Nós</span>
    </div>
  )
}

export default Title;