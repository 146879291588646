import './style.css';
import Title from './title';
import Carroussel from './carrousell';
import Button from '../services/button';

function About() {
  return(
    <div id='about'>
      <Title />
      <Carroussel />
      <div id='buttonSliderDown'>
        <Button />
      </div>
    </div>
  )
}

export default About;
