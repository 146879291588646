import './style.css'

const Button = () => {
  return(
    <>
      <div className="buttonBox">
        <button className='ctaButton'onClick={()=>{
          const element = document.getElementById('boxNameEnterprise')
          element.scrollIntoView({ behavior: 'smooth' });
        }}>Fale Conosco</button>
      </div>  
    </>
  )
}

export default Button