import './style.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import Button from '../../services/button';

function Carroussel() {
  const data = [
    {
      id: '1',
      title: 'Nosso Propósito',
      content: (
        <p className='contentModal'>
          <span className='blue'>Nosso propósito é transformar o cenário de conectividade</span>, implementando <span className='blue'>soluções inovadoras</span> e automatizadas que atendam às necessidades específicas dos nossos clientes, <span className='blue'>promovendo seu sucesso e, consequentemente, o nosso.</span>
        </p>
      ),
    },
    {
      id: '2',
      title: 'Nossa Experiência',
      content: (
        <p className='contentModal'>
          <span className='blue'>A Kernell Intelligence é uma empresa de consultoria com mais de 7 anos de experiência</span>. Oferecemos uma gama completa de serviços, incluindo <span className='blue'>monitoramento, roteamento, redes FTTx, redes wireless, documentação, servidores e muito mais</span>. Nossa abordagem é centrada na excelência, confiança e profissionalismo, sempre visando o crescimento dos nossos clientes.
        </p>
      ),
    },
    {
      id: '3',
      title: 'Nossa Missão',
      content: (
        <p className='contentModal'>
          <span className='blue'>Nossa missão é fornecer soluções inovadoras e confiáveis</span> que impulsionem a eficiência e o <span className='blue'>crescimento dos nossos parceiros</span>, promovendo uma conectividade de alta qualidade e sustentando a transformação digital.
        </p>
      ),
    },
    {
      id: '4',
      title: 'Nossa Visão',
      content: (
        <p className='contentModal'>
          <span className='blue'>Nossa visão é ser reconhecida como a empresa líder</span> em consultoria e engenharia para telecomunicações, destacando-se pela inovação, automação e confiança, <span className='blue'>contribuindo para a evolução e a excelência no setor de telecomunicações.</span>
        </p>
      ),
    },
  ];

  return (
    <div id='boxSwipper'>
       <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay]}
        slidesPerView={1}
        spaceBetween={20}
        pagination={{ clickable: true }}
        autoplay={{delay: 5000}}
        >
        {data.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="slide-content">
              <div className="boxSwipperGlass">
                <div id='boxTitleSlider'>
                  <h2 className="slide-title">{item.title}</h2>
                </div>
                <div>
                  {item.content}
                </div>
                <div id='buttonSlider'>
                  <Button></Button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Carroussel;