import { useState } from 'react';
import './style.css';
import emailjs from '@emailjs/browser';

function Mail() {
  const [name, setName] = useState('');
  const [enterprise, setEnterprise] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState([]);
  const [message, setMessage] = useState('');
  const [errorMessages, setErrorMessages] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!name.trim()) {
      isValid = false;
      errors.name = 'Por favor, insira seu nome.';
    }

    if (!enterprise.trim()) {
      isValid = false;
      errors.enterprise = 'Por favor, insira o nome da sua empresa.';
    }

    if (!email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      isValid = false;
      errors.email = 'Por favor, insira um endereço de e-mail válido.';
    }

    if (!phone.trim()) {
      isValid = false;
      errors.phone = 'Por favor, insira seu número de telefone.';
    }

    if (subject.length === 0) {
      isValid = false;
      errors.subject = 'Por favor, selecione pelo menos um assunto.';
    }

    if (!message.trim()) {
      isValid = false;
      errors.message = 'Por favor, insira sua mensagem.';
    }

    setErrorMessages(errors);
    return isValid;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);

      const templateParams = {
        from_name: name,
        enterprise: enterprise,
        email: email,
        phone: phone,
        subject: subject.join(', '),
        message: message,
      };

      emailjs
        .send('service_rccibws', 'template_fvi3o2n', templateParams, 'Bev4a1b4ZXVBgfqz7')
        .then(
          (response) => {
            console.log('Email enviado', response.status, response.text);
            setLoading(false);
            setShowSuccessMessage(true);
            setName('');
            setEnterprise('');
            setEmail('');
            setPhone('');
            setSubject([]);
            setMessage('');
            setErrorMessages({});
            setTimeout(() => {
              setShowSuccessMessage(false);
            }, 1000); // A mensagem desaparece após 1 segundo
          },
          (err) => {
            console.log('Erro:', err);
            setLoading(false);
            // Lidar com o erro se necessário
          }
        );
    }
  };

  const opcoesAssunto = [
    { valor: 'Monitoramento', label: 'Monitoramento' },
    { valor: 'Access Point', label: 'Access Point' },
    { valor: 'Documentação', label: 'Documentação' },
    { valor: 'Roteamento', label: 'Roteamento' },
    { valor: 'Servidores', label: 'Servidores' },
    { valor: 'Kernell Control', label: 'Kernell Control' },
  ];

  return (
    <div className="container">
      <form className="form" onSubmit={sendEmail}>
        <div id="boxNameEnterprise">
          <div id="boxName">
            <div className="titleContact">
              <span>Nome</span>
            </div>
            <div id="inputName">
              <input
                className={`input ${errorMessages.name ? 'invalid' : ''}`}
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              {errorMessages.name && <p className="error-message">{errorMessages.name}</p>}
            </div>
          </div>
          <div id="boxEnterprise">
            <div className="titleContact">
              <span>Empresa</span>
            </div>
            <div id="inputEnterprise">
              <input
                className={`input ${errorMessages.enterprise ? 'invalid' : ''}`}
                type="text"
                onChange={(e) => setEnterprise(e.target.value)}
                value={enterprise}
              />
              {errorMessages.enterprise && (
                <p className="error-message">{errorMessages.enterprise}</p>
              )}
            </div>
          </div>
        </div>

        <div id="boxEmailPhone">
          <div id="boxEmail">
            <div className="titleContact">
              <span>Email</span>
            </div>
            <div id="inputEmail">
              <input
                className={`input ${errorMessages.email ? 'invalid' : ''}`}
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              {errorMessages.email && <p className="error-message">{errorMessages.email}</p>}
            </div>
          </div>
          <div id="boxPhone">
            <div className="titleContact">
              <span>Número de Telefone</span>
            </div>
            <div id="inputPhone">
              <input
                className={`input ${errorMessages.phone ? 'invalid' : ''}`}
                type="text"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />
              {errorMessages.phone && <p className="error-message">{errorMessages.phone}</p>}
            </div>
          </div>
        </div>

        <div className="checkbox-grid">
          <div className="titleSubject">
            <span>Selecionar Assunto</span>
          </div>
          <div id="subjects">
            {opcoesAssunto.map((opcao, index) => (
              <div
                key={opcao.valor}
                className={`checkbox-container ${
                  index % 2 === 0 ? 'left-column' : 'right-column'
                }`}
              >
                <input
                  type="checkbox"
                  id={opcao.valor}
                  name="Subject"
                  value={opcao.valor}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setSubject((prevSubjects) =>
                      checked
                        ? [...prevSubjects, opcao.valor]
                        : prevSubjects.filter((subject) => subject !== opcao.valor)
                    );
                  }}
                  checked={subject.includes(opcao.valor)}
                />
                <label htmlFor={opcao.valor}>{opcao.label}</label>
              </div>
            ))}
          </div>
          <div>
            {errorMessages.subject && <p className="error-message">{errorMessages.subject}</p>}
          </div>
        </div>

        <div id="boxMessage">
          <div className="titleContact">
            <span>Menssagem</span>
          </div>
          <div id="inputMessage">
            <input
              className={`input ${errorMessages.message ? 'invalid' : ''}`}
              type="text"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
            {errorMessages.message && <p className="error-message">{errorMessages.message}</p>}
          </div>
        </div>
        <div id="boxButtonSend">
          {showSuccessMessage ? (
            <div className="success-message">Enviado com sucesso!</div>
          ) : (
            <button
              id="buttonSend"
              type="submit"
              className={loading ? 'loading' : ''}
              disabled={loading}
            >
              {loading ? <div className="button-spinner"></div> : 'Enviar Menssagem'}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default Mail;
