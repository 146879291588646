import './App.css';
import Header from './components/header';
import Contact from './components/contact';
import Home from './components/home';
import Services from './components/services';
import About from './components/about';

function App() {
  return (
    <div id="App">
      <Header></Header>
      <Home></Home>
      <Services></Services>
      <About></About>
      <Contact></Contact>
    </div>
  );
}

export default App;
