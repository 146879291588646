import Button from './button';
import './style.css';
import IconAcessPoint from '../../assets/iconAcessPoint.svg';
import IconDocumentation from '../../assets/iconDocumentation.svg';
import IconKernellControll from '../../assets/logo_no-background.svg';
import IconMonitoring from '../../assets/iconMonitoring.svg';
import IconRouting from '../../assets/iconRouting.svg';
import IconServers from '../../assets/iconServers.svg';

function Services() {
  return (
    <div id='myService'>
      <div className="services-header">
        <div className="serviceLine"></div>
        <h2 id="service-title">Nossas Soluções</h2>
      </div>
      <div className="services-content">
        <div className="service-column">
          <div className="service-card">
            <div className="icon-title">
              <img src={IconMonitoring} alt="Monitoramento" className="service-icon" />
              <h3>Monitoramento</h3>
            </div>
            <p className="description">
              <span>Monitoramos sua infraestrutura 24/7</span> com ferramentas como <span>Zabbix, Grafana e The Dude,</span> garantindo detecção e correção proativa de falhas em tempo real, maximizando a disponibilidade da rede.
            </p>
          </div>
          <div className="service-card">
            <div className="icon-title">
              <img src={IconRouting} alt="Roteamento" className="service-icon" />
              <h3>Roteamento</h3>
            </div>
            <p className="description">
            <span>Especialistads em OSPF e BGP, IPv4, IPv6 e muito mais,</span> configuramos roteadores <span>MikroTik, Huawei e outras marcas,</span> assegurando tráfego eficiente, seguro e adaptado às demandas crescentes do seu negócio.
            </p>
          </div>
        </div>
        <div className="service-column">
          <div className="service-card">
            <div className="icon-title">
              <img src={IconAcessPoint} alt="Redes Access Point" className="service-icon" />
              <h3>Redes Access Point</h3>
            </div>
            <p className="description">
              Configuramos e damos suporte para <span>redes FTTX (Huawei, ZTE, Vsol, Parks, Datacom, Cdada, Gigatelco e outras) e rádios variados,</span> garantindo desempenho e conectividade em qualquer ambiente.
            </p>
          </div>
          <div className="service-card">
            <div className="icon-title">
              <img src={IconServers} alt="Servidores" className="service-icon" />
              <h3>Servidores</h3>
            </div>
            <p className="description">
              Desenvolvemos servidores para <span>Speedtest, Radius, TACACS e outros,</span> oferecendo autenticação, <span>segurança e controle otimizado para uma rede</span> robusta e segura.
            </p>
          </div>
        </div>
        <div className="service-column">
          <div className="service-card">
            <div className="icon-title">
              <img src={IconDocumentation} alt="Documentação" className="service-icon" />
              <h3>Documentação</h3>
            </div>
            <p className="description">
            <span>Fornecemos documentação detalhada e gratuita exclusivamente para clientes,</span> criando mapas e relatórios completos para <span>facilitar expansões, manutenções e garantir suporte contínuo.</span>
            </p>
          </div>
          <div className="service-card">
            <div className="icon-title">
              <img src={IconKernellControll} alt="Kernell Controll" className="service-icon" />
              <h3>Kernell Controll</h3>
            </div>
            <p className="description">
              Nosso software <span>Kernell Controll, gratuito para clientes,</span> oferece monitoramento de vários equipamentos, <span>integrando dados de diversas marcas e proporcionando total controle da rede.</span>
            </p>
          </div>
        </div>
      </div>
      <div className="services-footer">
        <Button id="contact-button" text="Fale Conosco" />
      </div>
    </div>
  );
}

export default Services;
